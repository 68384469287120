/* eslint-disable */
import React, { createContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { baseUrl } from './defaultValues';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [auth, setAuth] = useState(false);
  // const [account, setAccount] = useState(4);
  const [account, setAccount] = useState(1);
  const [cookieValue, setCookieValue] = useState('');

  const updateUser = (value) => {
    setUser(value);
  };

  const updateToken = (value) => {
    setToken(value);
  };

  const updateCsrfToken = (value) => {
    setCsrfToken(value);
  };

  const updateAuth = (value) => {
    setAuth(value);
  };

  const updateAccount = (value) => {
    setAccount(value);
  };

  const getCsrfToken = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    try {
      const response = await axios.get(`${baseUrl}/api/v2/csrf/token`, config);
      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrfToken);
        Cookies.set('csrftoken', response.data.csrfToken);
        setCsrfToken(response.data.csrfToken);
      }
    } catch (error) {
      console.log('Error Csrf Token:', error);
    }
  };

  useEffect(() => {
    const jwttoken = localStorage.getItem('accessToken');
    const csrf = localStorage.getItem('csrftoken');
    if(!csrf)
    {
      getCsrfToken();
    }
    if (jwttoken) {
      updateToken(jwttoken);
      setCsrfToken(csrf);
      updateAuth(true);
      getCsrfToken();
    } else {
      Redirect('/login');
      updateToken(null);
      updateAuth(false);
      localStorage.clear();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
        updateToken,
        token,
        updateAuth,
        auth,
        updateAccount,
        account,
        updateCsrfToken,
        csrfToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
